.k-animation-container:has(.popup-error) {
  top: auto !important;
  left: auto !important;
  bottom: 0;
  right: 0;
}

.popup-error {
  margin: 0 20px 20px 0;
  padding: 10px;
}

.popup-error-title {
  .icon-close {
    font-size: 16px;
    color: map-get($sh-colors, "black-60");
  }
  font-size: 20px;
  color: map-get($sh-colors, "red");
}
.popup-error-subtitle {
  font-weight: 600;
  font-size: 15px;
}
