@font-face {
  font-family: 'SH Icon';
  src: url('../font/sh-icons.eot?11599021');
  src: url('../font/sh-icons.eot?11599021#iefix') format('embedded-opentype'),
       url('../font/sh-icons.woff2?11599021') format('woff2'),
       url('../font/sh-icons.woff?11599021') format('woff'),
       url('../font/sh-icons.ttf?11599021') format('truetype'),
       url('../font/sh-icons.svg?11599021#sh-icons') format('svg');
  font-weight: normal;
  font-style: normal;
}
/* Chrome hack: SVG is rendered more smooth in Windozze. 100% magic, uncomment if you need it. */
/* Note, that will break hinting! In other OS-es font will be not as sharp as it could be */
/*
@media screen and (-webkit-min-device-pixel-ratio:0) {
  @font-face {
    font-family: 'sh-icons';
    src: url('../font/sh-icons.svg?11599021#sh-icons') format('svg');
  }
}
*/
 
 [class^="icon-"]::before, [class*=" icon-"]::before {
  font-family: "SH Icon";
  font-style: normal;
  font-weight: normal;
  speak: never;
 
  display: inline-block;
  text-decoration: inherit;
  width: 1em;
  /*margin-right: .2em;*/
  text-align: center;
  /* opacity: .8; */
 
  /* For safety - reset parent styles, that can break glyph codes*/
  font-variant: normal;
  text-transform: none;
 
  /* fix buttons height, for twitter bootstrap */
  line-height: 1em;
 
  /* Animation center compensation - margins should be symmetric */
  /* remove if not needed */
  /*margin-left: .2em;*/
 
  /* you can be more comfortable with increased icons size */
  /* font-size: 120%; */
 
  /* Font smoothing. That was taken from TWBS */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
 
  /* Uncomment for 3D effect */
  /* text-shadow: 1px 1px 1px rgba(127, 127, 127, 0.3); */
}
 
.icon-360-degrees::before, .icon-360-grad { content: '\e847'; } /* '' */
.icon-apps::before, .icon-nine-dots::before { content: '\e85a'; } /* '' */
.icon-arrow-down-large-light::before { content: '\e874'; } /* '' */
.icon-arrow-down-large::before, .icon-arrow-down::before { content: '\e81f'; } /* '' */
.icon-arrow-down-medium-light::before { content: '\e864'; } /* '' */
.icon-arrow-down-medium::before { content: '\e83f'; } /* '' */
.icon-arrow-down-small-light::before { content: '\e863'; } /* '' */
.icon-arrow-down-small::before, .icon-dropdown::before, .icon-drop-down::before { content: '\e803'; } /* '' */
.icon-arrow-left-large-light::before { content: '\e87d'; } /* '' */
.icon-arrow-left-large::before, .icon-arrow-left::before { content: '\e81e'; } /* '' */
.icon-arrow-left-medium-light::before { content: '\e862'; } /* '' */
.icon-arrow-left-medium::before { content: '\e833'; } /* '' */
.icon-arrow-left-small-light::before { content: '\e850'; } /* '' */
.icon-arrow-left-small::before { content: '\e806'; } /* '' */
.icon-arrow-long-left::before { content: '\e842'; } /* '' */
.icon-arrow-long-right::before { content: '\e854'; } /* '' */
.icon-arrow-right-large-light::before { content: '\e87a'; } /* '' */
.icon-arrow-right-large::before, .icon-arrow-right::before { content: '\e81c'; } /* '' */
.icon-arrow-right-medium-light::before { content: '\e86e'; } /* '' */
.icon-arrow-right-medium::before, .icon-link-intern::before, .icon-intern::before { content: '\e827'; } /* '' */
.icon-arrow-right-small-light::before { content: '\e875'; } /* '' */
.icon-arrow-right-small::before { content: '\e80a'; } /* '' */
.icon-arrow-to-top-light::before { content: '\e85d'; } /* '' */
.icon-arrow-to-top::before { content: '\e84d'; } /* '' */
.icon-arrow-up-large-light::before { content: '\e826'; } /* '' */
.icon-arrow-up-large::before, .icon-arrow-top::before { content: '\e81d'; } /* '' */
.icon-arrow-up-medium-light::before { content: '\e878'; } /* '' */
.icon-arrow-up-medium::before, .icon-arrow-drop-up::before { content: '\e84f'; } /* '' */
.icon-arrow-up-small-light::before { content: '\e867'; } /* '' */
.icon-arrow-up-small::before { content: '\e840'; } /* '' */
.icon-arrow::before, .icon-link-extern::before, .icon-extern::before { content: '\e834'; } /* '' */
.icon-at::before, .icon-at-sign::before { content: '\e83e'; } /* '' */
.icon-bell::before { content: '\e855'; } /* '' */
.icon-book::before { content: '\e810'; } /* '' */
.icon-bookmark::before { content: '\e802'; } /* '' */
.icon-bullet-list::before { content: '\e82f'; } /* '' */
.icon-calculate::before { content: '\e87e'; } /* '' */
.icon-calendar::before { content: '\e804'; } /* '' */
.icon-chat::before { content: '\e846'; } /* '' */
.icon-check::before, .icon-checkmark::before { content: '\e818'; } /* '' */
.icon-close-fullscreen::before { content: '\e839'; } /* '' */
.icon-close::before { content: '\e817'; } /* '' */
.icon-comment::before { content: '\e843'; } /* '' */
.icon-delete::before { content: '\e86c'; } /* '' */
.icon-department::before { content: '\e86d'; } /* '' */
.icon-document::before { content: '\e814'; } /* '' */
.icon-dots-ellipsis-horizontal::before { content: '\e86a'; } /* '' */
.icon-dots-ellipsis-vertical::before, .icon-more::before  { content: '\e857'; } /* '' */
.icon-double-arrow-backward::before { content: '\e828'; } /* '' */
.icon-double-arrow-down-light::before { content: '\e837'; } /* '' */
.icon-double-arrow-down::before { content: '\e830'; } /* '' */
.icon-double-arrow-forward::before { content: '\e83a'; } /* '' */
.icon-double-arrow-up-light::before { content: '\e861'; } /* '' */
.icon-double-arrow-up::before { content: '\e801'; } /* '' */
.icon-download::before { content: '\e823'; } /* '' */
.icon-duration::before { content: '\e873'; } /* '' */
.icon-edit::before { content: '\e87c'; } /* '' */
.icon-email::before { content: '\e815'; } /* '' */
.icon-equipment-name::before { content: '\e838'; } /* '' */
.icon-facebook::before { content: '\e821'; } /* '' */
.icon-favorite::before, .icon-star::before, .icon-rating::before { content: '\e812'; } /* '' */
.icon-favorite-off::before, .icon-star-off::before { content: '\e859'; } /* '' */
.icon-feed::before { content: '\e841'; } /* '' */
.icon-filter::before { content: '\e858'; } /* '' */
.icon-fullscreen::before, .icon-large-view::before  { content: '\e809'; } /* '' */
.icon-google::before { content: '\e829'; } /* '' */
.icon-heart::before { content: '\e824'; } /* '' */
.icon-help::before { content: '\e877'; } /* '' */
.icon-hide::before { content: '\e83b'; } /* '' */
.icon-home::before, .icon-house::before { content: '\e807'; } /* '' */
.icon-hospital::before { content: '\e81b'; } /* '' */
.icon-id-card::before { content: '\e871'; } /* '' */
.icon-information::before, .icon-info::before { content: '\e835'; } /* '' */
.icon-instagram::before { content: '\e852'; } /* '' */
.icon-label::before { content: '\e876'; } /* '' */
.icon-layout-2x2::before { content: '\e819'; } /* '' */
.icon-layout-3x2::before { content: '\e81a'; } /* '' */
.icon-layout-3x3::before { content: '\e851'; } /* '' */
.icon-layout-list::before { content: '\e820'; } /* '' */
.icon-level-up::before { content: '\e87b'; } /* '' */
.icon-like::before { content: '\e87f'; } /* '' */
.icon-linkedin::before { content: '\e822'; } /* '' */
.icon-location-pin::before, .icon-pin::before  { content: '\e80c'; } /* '' */
.icon-locked::before { content: '\e879'; } /* '' */
.icon-log-in::before, .icon-sign-in::before { content: '\e831'; } /* '' */
.icon-log-out::before, .icon-sign-out::before { content: '\e832'; } /* '' */
.icon-menu::before, .icon-burger::before { content: '\e805'; } /* '' */
.icon-minus::before { content: '\e82e'; } /* '' */
.icon-movie::before { content: '\e868'; } /* '' */
.icon-pause::before { content: '\e825'; } /* '' */
.icon-phone::before, .icon-tel::before { content: '\e80b'; } /* '' */
.icon-play::before { content: '\e80d'; } /* '' */
.icon-plus::before { content: '\e82d'; } /* '' */
.icon-podcast::before { content: '\e85b'; } /* '' */
.icon-portfolio::before { content: '\e86b'; } /* '' */
.icon-print::before { content: '\e816'; } /* '' */
.icon-private::before { content: '\e85e'; } /* '' */
.icon-qr-bar-code::before { content: '\e860'; } /* '' */
.icon-question::before { content: '\e866'; } /* '' */
.icon-reload::before, .icon-refresh::before, .icon-replay::before { content: '\e853'; } /* '' */
.icon-scale-down::before { content: '\e83d'; } /* '' */
.icon-scale-up::before { content: '\e83c'; } /* '' */
.icon-search::before { content: '\e80f'; } /* '' */
.icon-send-message::before { content: '\e865'; } /* '' */
.icon-settings::before { content: '\e85f'; } /* '' */
.icon-share::before { content: '\e811'; } /* '' */
.icon-shopping-cart::before, .icon-basket::before { content: '\e80e'; } /* '' */
.icon-show::before { content: '\e869'; } /* '' */
.icon-slider-drag-drop::before, .icon-slider::before { content: '\e260'; } /* '' */
.icon-snippet::before { content: '\e85c'; } /* '' */
.icon-speakers-off::before, .icon-volume-muted::before, .icon-volume-off::before { content: '\e82a'; } /* '' */
.icon-speakers-on::before, .icon-volume-up::before, .icon-volume-down::before  { content: '\e82b'; } /* '' */
.icon-support::before { content: '\e870'; } /* '' */
.icon-text-check::before { content: '\e844'; } /* '' */
.icon-time::before { content: '\e872'; } /* '' */
.icon-tool::before, .icon-settings::before { content: '\e845'; } /* '' */
.icon-twitter::before { content: '\e813'; } /* '' */
.icon-upload::before { content: '\e82c'; } /* '' */
.icon-user::before { content: '\e800'; } /* '' */
.icon-video-hd::before { content: '\e848'; } /* '' */
.icon-video::before, .icon-start-video-call::before { content: '\e86f'; } /* '' */
.icon-video-skip-backward::before { content: '\e849'; } /* '' */
.icon-video-skip-forward::before, .icon-skip-forward::before { content: '\e84a'; } /* '' */
.icon-video-stop::before, .icon-skip-backward::before { content: '\e84b'; } /* '' */
.icon-view-carousel::before { content: '\e84c'; } /* '' */
.icon-warning::before, .icon-exclamationmark::before  { content: '\e836'; } /* '' */
.icon-world::before, .icon-globe::before { content: '\e808'; } /* '' */
.icon-youtube::before { content: '\e84e'; } /* '' */
