.k-scheduler-layout {
  border-radius: 5px;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.2), 0px -1px 2px rgba(0, 0, 0, 0.1);
  background-color: white;
  border-width: 0;

  .k-scheduler-head > .k-scheduler-group > .k-scheduler-row {
    border-color: map-get($sh-colors, "black-10");
    border-width: 0 1px 1px 0;
    & > .k-scheduler-cell:nth-of-type(2) {
      display: none;
    }
  }

  .k-scheduler-body > .k-scheduler-group > .k-scheduler-row {
    .k-scheduler-cell {
      max-height: 50px;
    }
    .k-scheduler-cell {
      border-width: 0;
    }
    .k-resource-row > .k-scheduler-row > .k-scheduler-cell:first-of-type {
      display: none;
    }
  }
}
.k-scheduler-layout-flex .k-scheduler-group .k-group-cell {
  flex: 1 1 20%;
  overflow: hidden;
}
.k-event {
  color: black;
  max-height: 51px;
  border-radius: 3px;
  display: flex;
  align-items: center;
}

.k-scheduler {
  font-family: "Siemens Sans", "Arial", sans-serif;
  font-size: 14px;
  border-color: transparent;
  background-color: transparent;
  box-shadow: none !important;
  margin-top: 54px;
}
.k-scheduler-toolbar {
  font-family: "Siemens Sans", "Arial", sans-serif;
  background-color: transparent;
  box-shadow: none;
  position: absolute;
  left: 15px;
  right: 15px;
  padding-bottom: 60px;
  min-width: 738px;
  .icon-button.k-button {
    padding: 7px;
    i::before {
      margin: 0;
    }
  }
  .popup-schedule-caption {
    .k-button {
      border-color: map-get($sh-colors, "black-60") !important;
      border-style: solid;
      border-width: 2px;
      border-radius: 10px;
      i {
        font-size: 12px;
      }
      width: 20px;
      height: 20px;
      padding: 0;
    }
    color: map-get($sh-colors, "black-60");
  }
  .k-button {
    padding: 7px 20px;
  }
  .scheduler-arrow-button {
    padding: 7px 7px;
  }
  .title {
    font-size: 20px;
    font-weight: 600;
  }
}
.k-scheduler-cell {
  max-height: 51px;
}
.k-scheduler-row .k-heading-cell {
  align-items: center;
}

.k-scheduler-layout-flex .k-scheduler-cell .date-header {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 5px;

  .weekday {
    line-height: 15px;
  }
  .day {
    font-size: 25px;
    line-height: 25px;
  }
}

.scheduler-dates {
  font-size: 18px;
  font-weight: 600;
  color: map-get($sh-colors, "black-60");
}

.popup-scheduler-caption-content {
  display: flex;
  flex-direction: row;
  gap: 10px;
  margin-top: 10px;
  .popup-scheduler-caption-column {
    display: flex;
    flex-direction: column;
    gap: 5px;
    .popup-scheduler-caption-item {
      display: flex;
      flex-direction: row;
      .caption-color:empty {
        height: 15px;
        width: 15px;
        margin-right: 10px;
        border-radius: 3px;
      }
    }
  }
}
.k-scheduler-cell {
  max-height: 51px;
}
.k-scheduler-row:has(.k-group-content .k-scheduler-row .Demo) {
  .k-heading-cell {
    background-color: #8fa6e0;
  }
}
.k-scheduler-row:has(.k-group-content .k-scheduler-row .Pret) {
  .k-heading-cell {
    background-color: #ceeffb;
  }
}
.k-scheduler-row:has(.k-group-content .k-scheduler-row .DemoPret) {
  .k-heading-cell {
    background-color: #f9bfc7;
  }
}
.k-scheduler-row:has(.k-group-content .k-scheduler-row .InstallForma) {
  .k-heading-cell {
    background-color: #bfe6cd;
  }
}

.kendo-pdf-document {
  background-color: #f9bfc7;
}

.popup-scheduler-filters {
  display: flex;
  flex-direction: column;
  width: 400px;
  gap: 15px;
  margin: 10px 0;
  .k-picker {
    height: 50px;
  }
  .k-input-inner {
    padding: 24px 16px 4px 16px;
  }
}

.scheduler-filters {
  position: absolute;
  top: 54px;
  left: 0;
  height: 51px;
  display: flex;
  justify-content: center;
}

.purposes-filter {
  gap: 5px;
}
