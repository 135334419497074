@import "../colors";

.k-grid {
  font-family: "Siemens Sans";
  border-width: 0;
  height: calc(100vh - 312px);
  td {
    padding: 15px 15px;
    font-size: 16px;
    line-height: 25px;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
  table {
    width: 100% !important;
  }
}

.k-grid-header {
  .k-header {
    vertical-align: middle;
    padding: 0;
    .k-cell-inner {
      margin: 0;
    }
  }
}

.k-cell-inner {
  .k-grid-header-menu {
    padding: 4px;
    .k-icon {
      font-size: 20px;
      width: 100%;
      height: 100%;
    }
  }
  .k-link {
    padding: 0 16px;
    font-size: 16px;
    line-height: 30px;
  }
}

.k-column-title {
  white-space: break-spaces;
}

.resource-cell {
  display: flex;
  flex-direction: column;
  font-size: 16px;
  line-height: 16px;
}
.resource-spare {
  font-size: 12px;
  line-height: 9px;
  display: flex;
  flex-direction: row;
}
.resource-spare > .resource-spare-item:first-of-type {
  margin-left: 0;
}

.resource-spare-item {
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  margin-left: 5px;
}

.status-cell {
  font-size: 100%;
  height: 16px;
  white-space: nowrap;
}

.button-edit {
  color: map-get($sh-colors, "petrol");
}

.button-delete {
  color: map-get($sh-colors, "red-dark");
}

.action-button {
  padding: 0 !important;
  i {
    font-size: 24px;
  }
}

.k-grid-pager {
  justify-content: center;
  font-family: "Siemens Sans";
  .k-pager-info {
    position: absolute;
    right: 0;
  }
  .k-i-caret-alt-left::before {
    content: "\e81e";
  }
  .k-i-caret-alt-right::before {
    content: "\e81c";
  }
  .k-link:has(.k-i-caret-alt-to-left) {
    display: none;
  }
  .k-link:has(.k-i-caret-alt-to-right) {
    display: none;
  }
  .k-pager-nav {
    color: map-get($sh-colors, "black-60");
    &:hover {
      color: map-get($sh-colors, "black-80");
      background-color: transparent;
    }
    &:focus {
      background-color: transparent;
    }
  }
}

.k-pager-numbers {
  gap: 2px;
  .k-link {
    height: 30px;
    min-width: 30px;
    &.k-selected {
      background-color: map-get($sh-colors, "black-10");
      color: map-get($sh-colors, "black-60");
      font-weight: 600;
    }
  }
}

.k-filter-row th {
  padding: 5px;
}
.k-filtercell span {
  display: flex;
  flex-flow: column nowrap;
  .k-input {
    flex-direction: row;
    height: auto;
    width: inherit;
  }
  span input {
    height: auto;
  }
}
.k-filtercell-operator {
  display: flex;
  .k-dropdown-operator {
    border-width: 0;
    border-radius: 15px !important;
    width: 30px;
    height: 30px;
    background-color: transparent;
  }
  .k-button {
    border-width: 0;
    border-radius: 15px !important;
    width: 30px;
    height: 30px;
    background-color: transparent;
  }
}
.k-filterable > .k-cell-inner {
  margin-inline-end: inherit;
}
.k-cell-inner .k-grid-header-menu {
  margin-inline-end: auto;
}

.k-filter-menu-container > .k-dropdownlist {
  display: none;
}
